<template>
    <div class="peoplelist">
        <div>
            <!-- 导师 -->
            <van-tabs v-model="active">
                <van-tab title="导师">
                    <!-- 导师名片 -->
                    <div
                        class="my-card peoplelist-namecard"
                        style="margin-top: 1rem"
                    >
                        <div>
                            <van-image
                                round
                                width="5rem"
                                height="5rem"
                                fit="cover"
                                src="https://img.yzcdn.cn/vant/cat.jpeg"
                                style="margin-right: 1rem"
                            />
                        </div>
                        <div class="my-font-md">
                            <div class="my-font-bold my-font-lg peoplelist-namecard-up">
                                <span>{{ professor.userNickname }}</span>
                            </div>
                            <div class="peoplelist-namecard-right">
                                <span>{{ professor.userDirection }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- 导师介绍 -->
                    <div class="my-card my-font-md peoplelist-introduction" v-html="user.userIntroduction" />
                </van-tab>
                <van-tab title="学生">
                    <div>
                        <!-- 学生 -->
                        <van-cell
                            class="my-font-lg my-font-bold"
                            style="margin-top: 1rem; margin-bottom: 1rem;"
                        >
                            <div style="text-align: center">博士生</div>
                        </van-cell>
                        <div
                            v-for="item in usersDoctor"
                            :key="item.userId"
                            class="my-card peoplelist-namecard"
                            style="margin-bottom: 0.5rem"
                            @click="toPersonPage(item.userId)"
                        >
                            <div>
                                <van-image
                                    v-if="!item.userPictureurl"
                                    round
                                    width="5rem"
                                    height="5rem"
                                    fit="cover"
                                    src="https://img.yzcdn.cn/vant/cat.jpeg"
                                    style="margin-right: 1rem"
                                />
                                <van-image
                                    v-else
                                    round
                                    width="5rem"
                                    height="5rem"
                                    fit="cover"
                                    :src="item.userPictureurl"
                                    style="margin-right: 1rem"
                                />
                            </div>
                            <div class="my-font-md">
                                <div
                                    class="my-font-bold my-font-lg peoplelist-namecard-up"
                                >
                                    <span>{{ item.userNickname }}</span>
                                </div>
                                <div class="peoplelist-namecard-right">
                                    <span>{{ item.userYear}}</span>
                                </div>
                                <div class="peoplelist-namecard-right">
                                    <span v-if="item.userDirection">{{
                                        item.userDirection
                                    }}</span>
                                    <span v-else>待定</span>
                                </div>
                            </div>
                        </div>
                        <van-cell
                            class="my-font-lg my-font-bold"
                            style="margin-top: 1rem; margin-bottom: 1rem;"
                        >
                            <div style="text-align: center">硕士生</div>
                        </van-cell>

                        <div
                            v-for="item in usersPostgraduate"
                            :key="item.userId"
                            class="my-card peoplelist-namecard"
                            style="margin-bottom: 0.5rem"
                            @click="toPersonPage(item.userId)"
                        >
                            <div>
                                <van-image
                                    v-if="!item.userPictureurl"
                                    round
                                    width="5rem"
                                    height="5rem"
                                    fit="cover"
                                    src="https://img.yzcdn.cn/vant/cat.jpeg"
                                    style="margin-right: 1rem"
                                />
                                <van-image
                                    v-else
                                    round
                                    width="5rem"
                                    height="5rem"
                                    fit="cover"
                                    :src="item.userPictureurl"
                                    style="margin-right: 1rem"
                                />
                            </div>
                            <div class="my-font-md">
                                <div
                                    class="my-font-bold my-font-lg peoplelist-namecard-up"
                                >
                                    <span>{{ item.userNickname }}</span>
                                </div>
                                <div class="peoplelist-namecard-right">
                                    <span>{{ item.userYear}}</span>
                                </div>
                                <div class="peoplelist-namecard-right">
                                    <span v-if="item.userDirection">{{
                                        item.userDirection
                                    }}</span>
                                    <span v-else>待定</span>
                                </div>
                            </div>
                        </div>
                        <van-cell
                            class="my-font-lg my-font-bold"
                            style="margin-top: 1rem; margin-bottom: 1rem;"
                        >
                            <div style="text-align: center">已毕业</div>
                        </van-cell>
                        <div
                            v-for="item in usersGone"
                            :key="item.userId"
                            class="my-card peoplelist-namecard"
                            style="margin-bottom: 0.5rem"
                            @click="toPersonPage(item.userId)"
                        >
                            <div>
                                <van-image
                                    v-if="!item.userPictureurl"
                                    round
                                    width="5rem"
                                    height="5rem"
                                    fit="cover"
                                    src="https://img.yzcdn.cn/vant/cat.jpeg"
                                    style="margin-right: 1rem"
                                />
                                <van-image
                                    v-else
                                    round
                                    width="5rem"
                                    height="5rem"
                                    fit="cover"
                                    :src="item.userPictureurl"
                                    style="margin-right: 1rem"
                                />
                            </div>
                            <div class="my-font-md">
                                <div
                                    class="my-font-bold my-font-lg peoplelist-namecard-up"
                                >
                                    <span>{{ item.userNickname }}</span>
                                </div>
                                <div class="peoplelist-namecard-right">
                                    <span>{{ item.userYear}}</span>
                                </div>
                                <div class="peoplelist-namecard-right">
                                    <span v-if="item.userDirection">{{
                                        item.userDirection
                                    }}</span>
                                    <span v-else>待定</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
import { getUserList, getProfessor, getUserById } from "../../api/common";
export default {
    components: {},
    data() {
        return {
            active: 0,
            professor: {},
            usersDoctor: [],
            usersPostgraduate: [],
            usersGone: [],
            users: [],
            user: {}
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.getUserList();
        this.getProfessor();
        this.getUserById();
    },
    methods: {
        toPersonPage(id) {
            this.$router.push({ name: "PersonPage", params: { id: id} });
        },
        getProfessor() {
            getProfessor().then((res) => {
                if (res.code === 20000) {
                    this.professor = res.data[0];
                }
            });
        },
        getUserList() {
            getUserList().then((res) => {
                // 处理博士、硕士、已毕业
                if (res.code === 20000) {
                    for (let user of res.data) {
                        if (user.userState === 1) {
                            // 在读
                            if (user.userRole === 2) {
                                // 硕士
                                this.usersPostgraduate.push(user);
                            } else if (user.userRole === 3) {
                                // 博士
                                this.usersDoctor.push(user);
                            }
                        } else {
                            // 毕业
                            this.usersGone.push(user);
                        }
                    }
                    this.users = this.usersDoctor.concat(
                        this.usersPostgraduate,
                        this.usersGone
                    );
                }
            });
        },
        getUserById() {
            const data = {
                userId: 19,
            };
            getUserById(data).then((res) => {
                if (res.code === 20000) {
                    this.user = res.data;
                }
            });
        },
    },
};
</script>

<style scoped>
.peoplelist {
    margin-bottom: 1rem;
}

.peoplelist-teacher {
    text-align: center;
}

.peoplelist-namecard {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.peoplelist-namecard-up {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 0rem;
}

.peoplelist-namecard-right {
    display: flex;
    flex-direction: column;
}

.peoplelist-introduction {
    margin-top: 1rem;

    line-height: 1.5rem;
}

/deep/ .peoplelist-introduction h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;

    font-size: 18px;
}

/deep/ .peoplelist-introduction h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;

    font-size: 18px;
}

/deep/ .peoplelist-introduction h3 {
    margin-top: 1rem;
    margin-bottom: 1rem;

    font-size: 18px;
}

/deep/ .peoplelist-introduction h4 {
    margin-top: 1rem;
    margin-bottom: 1rem;

    font-size: 18px;
}

/* /deep/ .peoplelist-introduction code {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
} */

/deep/ .peoplelist-introduction p {
    /* 段间距 */
    margin-bottom: 1rem;

    max-width: 1278px;
}

/deep/ .peoplelist-introduction table {
    border-top: 1px solid #dcdee2;
    border-left: 1px solid #dcdee2;
}

/deep/ .peoplelist-introduction table td,
table th {
    border-bottom: 1px solid #dcdee2;
    border-right: 1px solid #dcdee2;
    padding: 3px 5px;
}

/deep/ .peoplelist-introduction table th {
    border-bottom: 2px solid #dcdee2;
    border-right: 1px solid #dcdee2;
    text-align: center;
}

/deep/ .peoplelist-introduction blockquote {
    display: block;
    border-left: 8px solid #66b5f3;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
}

/deep/ .peoplelist-introduction code {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    background-color: #f1f1f1;
    border-radius: 3px;
    padding: 3px 5px;
    margin: 0 3px;
}

/deep/ .peoplelist-introduction pre code {
    display: block;
}

/deep/ .peoplelist-introduction ul,
ol {
    margin: 10px 0 10px 20px;
}
</style>
